import React, { useState } from 'react';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqItems = [
    {
      question: "What can Supertutor do for my memory and learning?",
      answer: "Supertutor is your AI-powered memory enhancement companion! It uses scientifically-proven techniques to boost your retention by up to 90%: active recall through adaptive quizzes, musical mnemonics that increase retention by 73%, and custom educational memes that provide an 83% memory boost through emotional engagement. Whether you're studying for an exam or learning a new skill, Supertutor's combination of tools helps you remember information better and longer."
    },
    {
      question: "How does Supertutor's memory enhancement work?",
      answer: "Supertutor uses a powerful combination of proven memory techniques: First, it creates custom quizzes that use active recall - the most effective way to cement information in your long-term memory. Then, it generates catchy musical mnemonics that help you remember through melody and rhythm. It also creates memorable memes that connect concepts to emotions and humor, making them stick better in your mind. Finally, it tracks your progress and adapts to focus more on what you need to review most."
    },
    {
      question: "What makes Supertutor different from regular study tools?",
      answer: "Unlike traditional study methods that rely on passive reading (which has only a 10% retention rate), Supertutor uses multiple scientifically-proven memory enhancement techniques. Our AI combines visual learning (65% faster learning rate), musical mnemonics (73% better recall), and active recall quizzes (90% retention rate) - all personalized to your learning style. Plus, our multiplayer games add social learning, which increases engagement by 55% and improves long-term retention."
    },
    {
      question: "How do I get started with memory enhancement?",
      answer: "Getting started is simple! After signing up, just input what you need to memorize. Supertutor will immediately create a personalized memory enhancement plan using all our tools: custom quizzes for active recall, musical mnemonics for auditory learning, educational memes for visual memory, and interactive games for engagement. You'll see your retention improve from the very first session, and our progress tracking helps you focus on what needs more attention."
    },
    {
      question: "Why is Supertutor's approach to memorization so effective?",
      answer: "Supertutor's effectiveness comes from combining multiple proven memory enhancement techniques with AI personalization. <br /><br/> Our active recall quizzes adapt to your responses, focusing more on what you need to review. The musical mnemonics create emotional connections that make information stick better in your memory. Educational memes add humor and visual elements that boost retention through multiple memory pathways. <br /><br/> Plus, our goal-tracking system helps you maintain consistent practice, which is crucial for long-term retention. By engaging multiple learning styles and memory systems simultaneously, Supertutor helps you achieve dramatically better retention rates compared to traditional study methods."
    }
  ];
  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div style={{ marginTop: '40px', fontFamily: 'Arial, sans-serif', maxWidth: '800px', margin: '0 auto' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Frequently Asked Questions:</h2>
      {faqItems.map((item, index) => (
        <div key={index} style={{ marginBottom: '15px' }}>
          <div 
            onClick={() => toggleQuestion(index)}
            style={{
              backgroundColor: 'white',
              padding: '15px',
              borderRadius: '8px',
              cursor: 'pointer',
              boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <h3 style={{ margin: 0 }}>{item.question}</h3>
            <svg 
              width="20" 
              height="20" 
              viewBox="0 0 20 20" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
              style={{
                transform: openIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease'
              }}
            >
              <path d="M5 7.5L10 12.5L15 7.5" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          {openIndex === index && (
            <div 
              style={{
                backgroundColor: 'white',
                padding: '15px',
                textAlign: 'left',
                borderRadius: '0 0 8px 8px',
                marginTop: '2px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
              }}
            >
              <div 
                style={{ lineHeight: '1.6', color: '#333' }}
                dangerouslySetInnerHTML={{ __html: item.answer.replace(/\n\s*\n/g, '<br><br>') }}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;