import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { auth, firestore } from './firebase';
import { useNavigate } from 'react-router-dom';
import './MySuperAnswers.css';

function MySuperAnswers() {
  const navigate = useNavigate();
  const [conversations, setConversations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showOptions, setShowOptions] = useState(null);
  const [renameTitle, setRenameTitle] = useState('');

  useEffect(() => {
    const fetchConversations = async () => {
      if (auth.currentUser) {
        const q = query(collection(firestore, 'users', auth.currentUser.uid, 'conversations'));
        const querySnapshot = await getDocs(q);
        const fetchedConversations = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        const sortedConversations = fetchedConversations.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
        setConversations(sortedConversations);
      }
    };

    fetchConversations();
  }, []);

  const handleConversationClick = (conversationId) => {
    navigate(`/${conversationId}`);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOptionsClick = (id) => {
    setShowOptions(id);
    setRenameTitle('');
  };

  const handleDeleteClick = async (id) => {
    try {
      await deleteDoc(doc(firestore, 'users', auth.currentUser.uid, 'conversations', id));
      setConversations(conversations.filter((conversation) => conversation.id !== id));
      setShowOptions(null);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleRenameClick = (id) => {
    const item = conversations.find((conversation) => conversation.id === id);
    setRenameTitle(item.title);
  };

  const handleRenameSubmit = async (e, id) => {
    e.preventDefault();
    try {
      const newTitle = renameTitle.trim();
      await updateDoc(doc(firestore, 'users', auth.currentUser.uid, 'conversations', id), {
        title: newTitle,
      });
      setConversations(
        conversations.map((conversation) =>
          conversation.id === id ? { ...conversation, title: newTitle } : conversation
        )
      );
      setShowOptions(null);
    } catch (error) {
      console.error('Error renaming item:', error);
    }
  };

  const filteredItems = conversations.filter((conversation) =>
    conversation.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="my-super-answers">
      <h2>My History</h2>
      <input
        type="text"
        placeholder="Search conversations..."
        value={searchTerm}
        onChange={handleSearch}
        className="search-input"
      />
      <div className="items-list">
        {filteredItems.map((item) => (
          <div key={item.id} className="item">
            <div className="item-header">
              <span
                onClick={() => handleOptionsClick(item.id)}
                className="options-button"
              >
                ⋮
              </span>
              <span className="item-date">
                {item.createdAt.toDate().toLocaleString()}
              </span>
            </div>
            {showOptions === item.id && (
              <div className="item-options">
                <button onClick={() => handleDeleteClick(item.id)}>Delete</button>
                <button onClick={() => handleRenameClick(item.id)}>Rename</button>
                {renameTitle && (
                  <form onSubmit={(e) => handleRenameSubmit(e, item.id)} className="rename-form">
                    <input
                      type="text"
                      value={renameTitle}
                      onChange={(e) => setRenameTitle(e.target.value)}
                    />
                    <button type="submit">Save</button>
                  </form>
                )}
              </div>
            )}
            <button
              onClick={() => handleConversationClick(item.id)}
              className="item-button"
            >
              {item.title}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MySuperAnswers;
