import React from 'react';
import './Careers.css';

const Careers = () => {
  const handleApplyClick = () => {
    window.location.href = `mailto:adam@aisupertutor.org?subject=Career Opportunity at Supertutor&body=I'm interested in applying for a role at Supertutor.%0D%0A%0D%0AWebsite: https://supertutorai.co/%0D%0A%0D%0AMake memorization easy with AI-powered learning tools.%0D%0AGet instant explanations, custom quizzes, songs, multiplayer games, flashcards and memes for super retention.`;
  };

  const positions = [
    {
      title: "Marketing Intern",
      type: "Remote, Paid Internship",
      compensation: "50% monthly recurring commissions per referral plus benefits",
      description: "Join our dynamic marketing team and help drive growth for an innovative EdTech platform.",
      responsibilities: [
        "Conduct market research and competitive analysis",
        "Create and execute marketing campaigns",
        "Manage social media presence",
        "Support sales initiatives and lead generation",
        "Analyze marketing metrics and prepare reports",
        "Contribute to content creation and strategy"
      ],
      qualifications: [
        "Currently pursuing or recently completed degree in Marketing or related field",
        "Strong written and verbal communication skills",
        "Experience with digital marketing tools and social media platforms",
        "Analytical mindset with attention to detail",
        "Self-motivated with ability to work independently",
        "Interest in education technology and AI"
      ]
    },
    {
      title: "Social Media Content Creator",
      type: "Remote, Contract",
      compensation: "Competitive pay based on experience",
      description: "Create engaging content that educates and inspires our growing community.",
      responsibilities: [
        "Develop creative content for multiple social platforms",
        "Create and edit short-form videos",
        "Design engaging social media graphics",
        "Write compelling copy for posts",
        "Monitor social media trends and engagement",
        "Collaborate with marketing team on content strategy"
      ],
      qualifications: [
        "Proven experience in social media content creation",
        "Proficiency in video editing and graphic design tools",
        "Strong understanding of social media platforms",
        "Creative storytelling abilities",
        "Experience with educational content is a plus",
        "Portfolio of previous work required"
      ]
    },
    {
      title: "Product Design Intern",
      type: "Remote, Paid Internship",
      compensation: "Competitive internship stipend",
      description: "Help shape the future of educational technology through innovative design solutions.",
      responsibilities: [
        "Create user interface designs for web and mobile",
        "Contribute to user experience research",
        "Design interactive prototypes",
        "Collaborate with development team",
        "Participate in design reviews and iterations",
        "Help maintain design system consistency"
      ],
      qualifications: [
        "Pursuing degree in UI/UX Design or related field",
        "Proficiency in design tools (Figma, Adobe Creative Suite)",
        "Understanding of user-centered design principles",
        "Basic knowledge of HTML/CSS",
        "Strong visual design skills",
        "Ability to work in an agile environment"
      ]
    }
  ];

  return (
    <div className="careers-container">
      <div className="careers-hero">
        <img 
          src="/supertutorlogo.png" 
          alt="Supertutor" 
          className="careers-logo"
        />
        <h1>Join Our Team</h1>
        <p className="hero-subtitle">Help shape the future of education with AI</p>
      </div>

      <section className="careers-content">
        <h2>Open Positions</h2>
        
        <div className="positions-grid">
          {positions.map((position, index) => (
            <div className="position-card" key={index}>
              <div className="position-header">
                <h3>{position.title}</h3>
                <span className="position-type">{position.type}</span>
              </div>
              <div className="position-details">
                <p className="compensation">{position.compensation}</p>
                <p className="description">{position.description}</p>
                
                <div className="responsibilities">
                  <h4>What you'll do</h4>
                  <ul>
                    {position.responsibilities.map((resp, i) => (
                      <li key={i}>{resp}</li>
                    ))}
                  </ul>
                </div>

                <div className="qualifications">
                  <h4>What we're looking for</h4>
                  <ul>
                    {position.qualifications.map((qual, i) => (
                      <li key={i}>{qual}</li>
                    ))}
                  </ul>
                </div>
                <button className="apply-button" onClick={handleApplyClick}>
                  Apply for this position
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Careers;